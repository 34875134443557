input[type="button"].copy {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.hashing-output-field {
  width: 100%;
  height: 150px;
}

.string-length-input {
  height: 40vh;
}
