@import "src/bx5/theme";

.counter-wrapper {
  background-color: $color-accent;
  padding: 50px;
  border-radius: 5px;
  color: $color-dark;
  border: 1px solid $color-dark;
  font-size: 80px;
  margin: auto;

  box-shadow: 0 0 0 1px $color-dark, 0 0 0 3px $color-accent-lighter;


  width: 300px;
  aspect-ratio: 1 / 1;

  display: grid;
  place-items: center;
}

.counter-wrapper {
  margin-top: 20px;

}

.counter-wrapper .counter {
  width: fit-content;
  height: fit-content;
}
