.text-center {
  text-align: center;
}

.notool-logo {
  margin: auto;
  max-width: 100%;
  display: block;
}

input[type="button"].copy-to-clipboard {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
