/*
* Copyright Moritz Zwerger 2022-2024. All rights reserved!
*/

// colors
$color-light: #cdd2b4;
$color-dark: #08131e;
$color-accent: #2ca256;

$color-info: #4BDDE2;
$color-success: #17a82f;
$color-warning: #f5c517;
$color-error: #f30b46;

$color-hover: rgba(255, 255, 255, 0.08);

// permutations
$perm-lighter: 5%;
$perm-highlight: 5%;


// generated colors
$color-dark-lighter: lighten($color-dark, $perm-lighter);
$color-light-darker: darken($color-light, $perm-lighter);

$color-accent-lighter: lighten($color-accent, $perm-lighter);
$color-accent-darker: darken($color-accent, $perm-lighter);

$color-info-lighter: lighten($color-info, $perm-lighter);
$color-success-lighter: lighten($color-success, $perm-lighter);
$color-warning-lighter: lighten($color-warning, $perm-lighter);
$color-error-lighter: lighten($color-error, $perm-lighter);
